import React from 'react'
import p1 from '../../images/products/p (1).jpeg'
import p2 from '../../images/products/p (2).jpeg'
import p3 from '../../images/products/p (3).jpeg'
import p4 from '../../images/products/p (4).jpeg'
import p5 from '../../images/products/p (5).jpeg'
import p6 from '../../images/products/p (6).jpeg'
import p7 from '../../images/products/p (7).jpeg'
import p8 from '../../images/products/p (8).jpeg'
import p9 from '../../images/products/p (9).jpeg'
import p10 from '../../images/products/p (10).jpeg'
import p11 from '../../images/products/p (11).jpeg'
import p12 from '../../images/products/p (12).jpeg'
import p13 from '../../images/products/p (13).jpeg'
import p14 from '../../images/products/p (14).jpeg'
import p15 from '../../images/products/p (15).jpeg'
import p16 from '../../images/products/p (16).jpeg'
import p17 from '../../images/products/p (17).jpeg'
import p18 from '../../images/products/p (18).jpeg'
import p19 from '../../images/products/p (19).jpeg'
import p20 from '../../images/products/p (20).png'
let arr = [p1, p2, p3, p4, p9, p10, p5, p6, p7, p8, p16, p17, p11, p12, p13, p14, p15, p18, p19, p20]
const Products = () => {
    return (
        <div id="product">
            <div className="header">
                Our Products
            </div>
            <div className="content-img">
                {
                    arr.map((p, i) =>
                        <img src={p} alt={"p" + (i + 1)} />
                    )
                }
            </div>
        </div>
    )
}

export default Products
