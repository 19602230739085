import React from 'react'
import AboutComponent from '../../components/AboutComponent'
import ContactUs from '../../components/ContactUs'
import FooterComponent from '../../components/FooterComponent'
// import ComingSoonComponent from '../../components/ComingSoonComponent'
import HeaderComponent from '../../components/HeaderComponent'
import LandingComponent from '../../components/LandingComponent'
import Products from '../../components/Products'

const MainContainer = () => {
    return (
        <React.Fragment>
            <HeaderComponent />
            <LandingComponent />
            <AboutComponent />
            <Products />
            <ContactUs />
            <FooterComponent />
            {/* <ComingSoonComponent /> */}
        </React.Fragment>
    )
}

export default MainContainer
