import React from 'react'

const FooterComponent = () => {
    return (
        <div className="footer">
            <div className="first-half">
                &copy; All right reserved by Healthsons pharma 2021
            </div>
        </div>
    )
}

export default FooterComponent
