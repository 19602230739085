import React from 'react'

const AboutComponent = () => {
    return (
        <div id="about">
            <div className="header">
                About
            </div>
            <div className="content">
                Healthsons Pharmaceutical Private Limited headquartered in Kolkata, West Bengal that sells pharmaceutical formulations and much more. We constantly work towards ensuring access to high quality and affordable medicines to support patients in need. Which is why, we have been trusted by health care professionals and patients. At healthsons, we are guided at all times by our promise of caring for our customers’ health and well-being. Caring For Life has always been and continues to remain, our guiding purpose. To achieve this objective, we have invested in research, continuous learning and technology to develop high quality and affordable medicines to address unmet patient needs
            </div>
        </div>
    )
}

export default AboutComponent
