import React from 'react'
import { PhoneOutlined } from '@ant-design/icons';
const LandingComponent = () => {
    return (
        <div className="landing-component" id="landing">
            <div className="carousel-component">
                <div className="carousel-content five">
                    <h1 className="title">
                        <div className="title-text">
                            Healthsons Pharmaceutical Private Limited
                            <div class="text-box">

                                <a href="tel:+917903190445" class="btn btn-white btn-animate"><PhoneOutlined rotate={90} /> &nbsp;&nbsp;Call us</a>
                            </div>
                        </div>
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default LandingComponent
