import React, { useState } from 'react'
import hslogo from '../../images/hslogo.png'
const HeaderComponent = () => {
    const [active, setactive] = useState("home")
    return (
        <div className="header-component">
            <div className="logo">
                <img src={hslogo} alt="hspharma" />
            </div>
            <div className="menu-items">
                <div className="menu-item" onClick={() => setactive("home")}>
                    <a href="#landing" className={active == "home" ? "active" : ""}>Home</a>
                </div>
                <div className="menu-item" onClick={() => setactive("about")}>
                    <a href="#about" className={active == "about" ? "active" : ""}>About</a>
                </div>
                <div className="menu-item" onClick={() => setactive("product")}>
                    <a href="#product" className={active == "product" ? "active" : ""}>Products</a>
                </div>
                <div className="menu-item" onClick={() => setactive("contact")}>
                    <a href="#contact" className={active == "contact" ? "active" : ""}>Contact</a>
                </div>
            </div>
        </div>
    )
}

export default HeaderComponent
