import React from 'react'
import { MailOutlined, HomeOutlined, PhoneOutlined, FacebookOutlined } from '@ant-design/icons';
const ContactUs = () => {
    return (
        <div id="contact">
            <div className="header">
                Contact Us
            </div>
            <div className="contact-content">
                <div className="wrap">
                    <div className="label">
                        For more information, contact us on :
                    </div>
                    <div className="item">
                        <div className="icon">
                            <MailOutlined />
                        </div>
                        <div className="value">
                            hspharma1010@gmail.com
                        </div>
                    </div>
                    <div className="item">
                        <div className="icon">
                            <HomeOutlined />
                        </div>
                        <div className="value">
                            165/1, Benaras Road, Howrah, West Bengal (711106)
                        </div>
                    </div>
                    <div className="item">
                        <div className="icon">
                            <PhoneOutlined rotate={90} />
                        </div>
                        <div className="value">
                            +91 7903190445
                            <br />
                            +91 9570375362
                        </div>
                    </div>
                    <div className="social item">
                        <a className="icons" href="https://www.facebook.com/Healthsons-Pharmaceutical-Pvt-ltd-108270004216318/" target="_blank">
                            <FacebookOutlined />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
